import colors from "./colors"
import components from "./components"
import prism from "./prism"
import styles from "./styles"

const blogTheme = {
  initialColorMode: `light`,
  colors,
  breakpoints: ["700px", "1280px"],
  fonts: {
    heading: `Lato`,
    body: `Open Sans`,
    monospace: `Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
  },
  fontSizes: [14, 16, 20, 24, 32, 48, 80],
  fontWeights: {
    light: 200,
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.7,
    heading: 1,
  },
  letterSpacings: {
    body: "normal",
    h1: "2px",
    h2: "1px",
    h3: "0.5px",
    h4: "0.25px",
  },
  sizes: {
    container: 672,
  },
  prism,
  components,
  styles,
}

export default blogTheme
