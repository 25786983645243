const styles = {
  ".attr-name": {
    fontStyle: `italic`,
  },
  ".comment": {
    color: `prism.comment`,
  },
  " .string, .url": {
    color: `prism.string`,
  },
  ".attr-name, .variable": {
    color: `prism.var`,
  },
  ".number": {
    color: `prism.number`,
  },
  ".builtin, .char, .constant, .function": {
    color: `prism.constant`,
  },
  ".punctuation, .selector, .doctype": {
    color: `prism.punctuation`,
  },
  ".class-name": {
    color: `prism.className`,
  },
  ".tag, .operator, .keyword": {
    color: `prism.tag`,
  },
  ".boolean": {
    color: `prism.boolean`,
  },
  ".property": {
    color: `prism.property`,
  },
  ".namespace": {
    color: `prism.namespace`,
  },
  ".color": {
    color: `prism.color`,
  },
}

export default styles
