const blue200 = "#BEE3F8"
const blue700 = "#2B6CB0"

const grey100 = "#F7FAFC"
const grey200 = "#EDF2F7"
const grey600 = "#718096"
const grey700 = "#4A5568"
const grey800 = "#2D3748"
const grey900 = "#1A202C"

const indigo100 = "#EBF4FF"
const indigo200 = "#C3DAFE"
const indigo500 = "#667EEA"
const indigo600 = "#5A67D8"
const indigo700 = "#4C51BF"
const indigo900 = "#3C366B"

const teal200 = "#B2F5EA"
const white = "#FFFFFF"
const twitterBlue = "#38A1F3"

const colors = {
  text: grey700,
  background: grey100,
  secondaryBackground: grey200,
  primary: indigo900,
  secondary: indigo600,
  highlight: indigo200,
  cardBackground: white,
  heroHeading: white,
  heroSubHeading: indigo200,
  navBarBackground: indigo900,
  navBarPrimary: white,
  navBarSecondary: teal200,
  blockQuoteBackground: indigo200,
  blockQuoteLeftBar: indigo600,
  subscribeBackground: indigo200,
  subscribeLeftBar: indigo600,
  subscribeButtonColor: indigo600,
  subscribeButtonText: white,
  twitterBackground: blue200,
  twitterLeftBar: twitterBlue,
  prism: {
    color: grey700,
    background: grey200,
    comment: `#93a1a1`,
    string: `#2aa198`,
    var: `#cb4b16`,
    number: `#268bd2`,
    constant: `#b58900`,
    punctuation: `#586e75`,
    className: `#b58900`,
    tag: indigo600,
    boolean: `#268bd2`,
    property: `#268bd2`,
    namespace: `#657b83`,
    highlight: `hsla(207, 45%, 15%, 1)`,
  },
  modes: {
    dark: {
      text: indigo100,
      background: grey900,
      secondaryBackground: grey800,
      primary: white,
      secondary: teal200,
      highlight: indigo600,
      cardBackground: grey600,
      blockQuoteBackground: indigo700,
      blockQuoteLeftBar: indigo500,
      subscribeBackground: indigo700,
      subscribeLeftBar: indigo500,
      subscribeButtonColor: indigo500,
      subscribeButtonText: white,
      twitterBackground: blue700,
      twitterLeftBar: twitterBlue,
      prism: {
        color: indigo100,
        background: grey800,
        comment: `#809393`,
        string: `#addb67`,
        var: `#f78c6c`,
        number: `#f78c6c`,
        constant: `#82aaff`,
        punctuation: `#c792ea`,
        className: `#ffc98b`,
        tag: teal200,
        boolean: `#ff5874`,
        property: `#80cbc4`,
        namespace: `#b2ccd6`,
        highlight: `hsla(207, 95%, 15%, 1)`,
      },
    },
  },
}

export default colors
