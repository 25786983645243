/** @jsx jsx */
import PrismCodeBlock from "@theme-ui/prism"
import { jsx, Themed } from "theme-ui"

import Copy from "./copy"

const getParams = (name = ``) => {
  const [lang, params = ``] = name.split(`^`)
  return [lang.split(`language-`).pop().split(`{`).shift()].concat(
    params.split(`&`).reduce((merged, param) => {
      const [key, value] = param.split(`=`)
      merged[key] = value
      return merged
    }, {})
  )
}

/*
 * MDX passes the code block as JSX
 * we un-wind it a bit to get the string content
 * but keep it extensible so it can be used with just children (string) and
 * className
 */
const CodeBlock = ({ children, className }) => {
  const [language, { title = ``, link = `` }] = getParams(
    children.props ? children.props.className : ``
  )
  const { children: content, ...props } = children.props
  return (
    <div sx={{ marginTop: "2em" }}>
      {title && (
        <span
          className={className}
          sx={{
            position: `relative`,
            marginRight: "auto",
            marginLeft: "0em",
            marginTop: "1em",
            marginBottom: "0em",
            maxWidth: "100%",
          }}
        >
          {link ? <Themed.a href={link}> {title}</Themed.a> : title}
        </span>
      )}
      <div
        sx={{
          position: `relative`,
        }}
        className={className}
      >
        <Copy
          fileName={title}
          className={className}
          sx={{
            position: `absolute`,
            top: 0,
            right: 0,
            borderRadius: 2,
          }}
          content={content}
        />
        <PrismCodeBlock
          {...props}
          language={language}
          className={`language-${language}`}
        >
          {content}
        </PrismCodeBlock>
      </div>
    </div>
  )
}

const shortcodes = {
  pre: CodeBlock,
}
export default shortcodes
