const styles = {
  root: {
    fontFamily: `body`,
    fontSize: 1,
    fontWeight: `normal`,
    lineHeight: `body`,
    color: `text`,
  },
  pre: {
    variant: `prism`,
    fontFamily: `monospace`,
    tabSize: 4,
    hyphens: `none`,
    marginBottom: 2,
    bg: `prism.background`,
    overflow: `auto`,
    borderRadius: 10,
    p: 3,
  },
  code: {
    fontFamily: `monospace`,
    // from typography overrideThemeStyles
    // "h1 code, h2 code, h3 code, h4 code, h5 code, h6 code"
    fontSize: `inherit`,
  },
  inlineCode: {
    borderRadius: `0.3em`,
    color: `text`,
    bg: `highlight`,
    paddingTop: `0.15em`,
    paddingBottom: `0.05em`,
    paddingX: `0.45em`,
    maxWidth: "100%",
    wordWrap: `break-word`,
  },
  a: {
    color: `secondary`,
    svg: {
      fill: `text`,
      marginRight: "0.2em",
    },
  },
  hr: {
    borderColor: `muted`,
  },
  p: {
    code: {
      fontSize: `inherit`,
    },
    figure: {
      figcaption: {
        fontSize: 0,
        color: `secondary`,
        textAlign: "center",
        marginTop: "0.5em",
      },
    },
  },
  li: {
    code: {
      fontSize: `inherit`,
    },
  },
  h1: {
    color: `primary`,
    fontFamily: `heading`,
    fontSize: 5,
    fontWeight: `light`,
    letterSpacing: `h1`,
    lineHeight: `heading`,
  },
  h2: {
    color: `primary`,
    fontFamily: `heading`,
    fontSize: 4,
    fontWeight: `normal`,
    letterSpacing: `h2`,
    lineHeight: `heading`,
    marginTop: "2em",
    marginBottom: "0em",
  },
  h3: {
    color: `secondary`,
    fontFamily: `heading`,
    fontWeight: `bold`,
    fontSize: 3,
    letterSpacing: `h3`,
    lineHeight: `heading`,
    marginTop: "2.5em",
    marginBottom: "0em",
  },
  h4: {
    color: `secondary`,
    fontFamily: `heading`,
    fontWeight: `bold`,
    fontSize: 2,
    letterSpacing: `h4`,
    lineHeight: `heading`,
    marginTop: "2.5em",
    marginBottom: "0em",
  },
  strong: {
    color: `primary`,
    fontWeight: `bold`,
  },
  em: {
    color: `secondary`,
  },
}

export default styles
